/**
 * Enum representing the delivery options for a dish.
 */
export enum DishDelivery {
  /**
   * The dish is available with delivery.
   */
  WITH_DELIVERY = 1,

  /**
   * The dish is not available for delivery.
   */
  NON_DELIVERY = 0,
}
